<template>
  <!-- 文献指南2.0 -->

  <div class="body">
    <div class="compass" ref="personDom" v-if="status">
      <!--搜索头部 -->
      <div class="topBox">
        <form action="/">
          <van-search
            class="search"
            background="white"
            v-model="search_value"
            placeholder="请输入指南、疾病、制定者等关键字"
            @clear="cancel"
            @search="onSearch"
          />
        </form>

        <!-- 选择部分 -->
        <div class="select">
          <div class="select_item" @click="getDocumentMaker">
            <div
              class="name"
              :class="MakerName == '' || MakerName == '全部' ? ' ' : 'sss'"
            >
              {{
                MakerName == "" || MakerName == "全部"
                  ? "选择制定者"
                  : MakerName
              }}
            </div>
            <div class="icon"><van-icon name="arrow-down" /></div>
          </div>
          <div class="select_item" @click="getDocumentDisease(disease)">
            <div
              class="name"
              :class="diseaseName == '' || diseaseName == '全部' ? ' ' : 'sss'"
            >
              {{
                diseaseName == "" || diseaseName == "全部"
                  ? "选择疾病"
                  : diseaseName
              }}
            </div>
            <div class="icon"><van-icon name="arrow-down" /></div>
          </div>
          <div class="select_item" @click="getYear">
            <div class="name" :class="year == '' ? ' ' : 'sss'">
              {{ year == "" ? "选择发布年份" : year }}
            </div>
            <div class="icon"><van-icon name="arrow-down" /></div>
          </div>
        </div>
      </div>

      <!-- 列表 -->
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="getList"
        class="list"
      >
        <div
          v-for="(list, index) in lists"
          :key="index"
          class="document-compass"
          :class="{ expanded: list.expanded }"
          @click="DocumentDiv(list.id, list.url, list.title, index)"
        >
          <div class="document-word">
            <div>
              <!-- 题目全部显示 -->
              <div class="document-word-title">
                <van-tag
                  v-if="list.is_top"
                  type="danger"
                  style="padding:4px 8px ;border-radius:4px;font-size:14px;margin-right:3px"
                  >置顶</van-tag
                >

                <span
                  style="color: #199bfb;font-size:16px;"
                  v-if="list.is_read == 0"
                  >[未读]</span
                >
                {{ list.title }}
                <div v-if="list.is_new == 1" class="document-word-new">新</div>
              </div>

              <!-- <div class="document-word-content">
                <div
                  :class="
                    operationlist[index].textOver &&
                    !operationlist[index].btnFold
                      ? 'inner over'
                      : 'inner'
                  "
                  ref="divRef"
                  :id="index"
                >
                  <span>{{ list.describe }}</span>
                </div>
                <span
                  class="btn"
                  v-if="operationlist[index].textOver"
                  @click.stop="
                    operationlist[index].btnFold = !operationlist[index].btnFold
                  "
                >
                  {{ operationlist[index].btnFold ? "收起" : "展开" }}
                </span>
              </div> -->
              <div class="document-word-content" v-if="list.describe">
                <template v-if="list.describe.length <= 42">{{
                  list.describe
                }}</template>
                <template v-else>
                  <template v-if="!list.expanded">
                    {{ list.describe.slice(0, 42) }}
                    <!-- 显示部分文本 -->
                    <span v-if="list.describe.length > 42">...</span>
                    <!-- 如果文本长度超过40，显示省略号 -->
                    <span
                      v-if="!list.expanded"
                      class="expand-btn"
                      @click.stop="toggleExpand(index)"
                    >
                      展开
                    </span>
                    <span
                      v-else
                      class="expand-btn"
                      @click.stop="toggleExpand(index)"
                    >
                      收起
                    </span>
                  </template>

                  <template v-else>
                    {{ list.describe }}
                    <span
                      v-if="!list.expanded"
                      class="expand-btn"
                      @click.stop="toggleExpand(index)"
                    >
                      展开
                    </span>
                    <span
                      v-else
                      class="expand-btn"
                      @click.stop="toggleExpand(index)"
                    >
                      收起
                    </span>
                    <!-- 显示完整文本 -->
                  </template>
                </template>
              </div>
              <!-- 专家组超出两行。。。。 -->
              <div
                class="document-word-expert"
                v-if="list.expertList.length !== 0"
              >
                <template v-if="list.expertList.length <= 13">
                  专家组：<span v-for="item in list.expertList" :key="item.id"
                    >{{ item.name }}
                  </span>
                </template>
                <template v-else>
                  <template v-if="!list.export">
                    专家组:<span
                      v-for="item in list.expertList.slice(0, 13)"
                      :key="item.id"
                      >{{ item.name }}&ensp;</span
                    >
                    <span v-if="list.expertList.length > 13">...</span>
                    <span
                      v-if="!list.export"
                      class="expand-btn"
                      @click.stop="toggleExpandExport(index)"
                    >
                      展开
                    </span>
                    <span
                      v-else
                      class="expand-btn"
                      @click.stop="toggleExpandExport(index)"
                    >
                      收起
                    </span>
                  </template>
                  <template v-else>
                    专家组：<span v-for="item in list.expertList" :key="item.id"
                      >{{ item.name }}&ensp;
                    </span>
                    <span
                      v-if="!list.export"
                      class="expand-btn"
                      @click.stop="toggleExpandExport(index)"
                    >
                      展开
                    </span>
                    <span
                      v-else
                      class="expand-btn"
                      @click.stop="toggleExpandExport(index)"
                    >
                      收起
                    </span>
                  </template>
                </template>
              </div>

              <!-- <div
                class="document-word-expert"
                v-if="list.expertList.length !== 0"
              >
                <div
                  :id="`ulid${index}`"
                  :class="
                    exportlist[index].textOverExport &&
                    !exportlist[index].btnFoldExport
                      ? 'inner over1'
                      : 'inner'
                  "
                >
                  专家组:
                  <span v-for="item in list.expertList" :key="item.id">
                    {{ item.name }}
                  </span>
                </div>
                <span
                  class="btn"
                  v-if="exportlist[index].textOverExport"
                  @click.stop="
                    exportlist[index].btnFoldExport = !exportlist[index]
                      .btnFoldExport
                  "
                >
                  {{ exportlist[index].btnFoldExport ? "收起" : "展开" }}
                </span>
              </div> -->
              <div class="document-word-date">
                <div>{{ list.show_date }} 发布</div>
                <div
                  style="color: #199bfb"
                  v-if="list.is_read == 0"
                  @click.stop="record(list.id, 'click', index)"
                >
                  标为已读
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <div
        v-if="page_no > 2 && $route.query.memberId == undefined"
        @click="openLogin"
        style="width:100%; margin:20px 0px;text-align: center;color:#1989fa"
      >
        登录后查看更多内容
      </div>
    </div>
    <div v-else class="compass">
      <!-- 制定者选择栏 -->
      <div v-if="type == ''">
        <div
          class="back"
          @click="back"
          :class="{ blueback: MakerName !== '' }"
          id="back"
        >
          <van-icon
            name="arrow-left"
            style="font-size:18px;line-height:40px"
          />&ensp;{{ MakerName == "" ? "选择制定者" : "制定者：" + MakerName }}
        </div>

        <div style="padding-top: 50px" id="title">
          <van-cell title="全部" @click="selectMaker1" />
          <van-index-bar :index-list="indexList" :sticky="sticky">
            <div v-for="item in indexBar" :key="item.type">
              <van-index-anchor :index="item.type" />

              <van-cell
                :title="data.name"
                v-for="data in item.list[0].documentMakerList"
                :key="data.id"
                @click="selectMaker(data)"
              />
            </div>
          </van-index-bar>
        </div>
      </div>
      <!-- 疾病选择栏 -->
      <div v-else>
        <div
          class="back"
          @click="back"
          :class="{ blueback: diseaseName !== '' }"
        >
          <van-icon
            name="arrow-left"
            style="font-size:18px;line-height:40px"
          />&ensp;
          {{ diseaseName == "" ? "选择疾病" : "疾病：" + diseaseName }}
        </div>
        <div style="padding-top: 50px">
          <van-cell title="全部" @click="selectMaker2" />
          <van-index-bar :index-list="indexList">
            <div v-for="item in indexBar" :key="item.type">
              <van-index-anchor :index="item.type" />
              <van-cell
                :title="data.name"
                v-for="data in item.list[0].documentDiseaseList"
                :key="data.id"
                @click="selectDisease(data)"
              />
            </div>
          </van-index-bar>
        </div>
      </div>
    </div>
    <div class="collect" @click="goCollect">
      <img src="../assets//collect.png" />
    </div>
    <!-- 年份选择 -->
    <van-popup v-model="datashow" position="bottom" :style="{ height: '30%' }">
      <van-picker
        title="选择发布年份"
        show-toolbar
        :columns="yearlist"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <login v-model="loginShow" :title="loginTitle"> </login>
  </div>
</template>

<script>
import {
  getMaker,
  getdocumentCompass,
  getDisease,
  getDocumentYear,
  postRecord,
} from "../service/documentCompass2.0_api";
import { setById } from "@/service/baseDocument";
import { recordOperationWX } from "@/utils/myUtils";
import { getAdmins } from "@/utils/adminDate";

import moment from "moment";
import login from "@/components/login.vue";
export default {
  components: { login },
  name: "documentCompass",
  data() {
    return {
      lists: [],
      indexBar: [],
      type: "",
      indexList: [],
      url: "",
      search_value: "",
      page_no: 1,
      title_list: [],
      channel: null,
      version: null,
      ip: null,
      count: 0,
      loading: false,
      finished: false,
      offset: 60,
      aType: "",
      heights: 0,
      Maker: [],
      status: true,
      MakerName: "",
      diseaseName: "",
      datashow: false,
      yearlist: [],
      year: "",
      sticky: false,
      loginShow: false,
      loginTitle: "",
      finishedText: "没有更多了",
      // operationlist: [],
      // exportlist: [],
    };
  },
  watch: {
    $route(now, old) {
      // now: 变化之后的数据
      // old: 变化之前的数据
      console.log("33", now.query == old.query);
      if (now.query.memberId) {
        this.lists = [];
        this.page_no = 1;
        this.getList();
      }
    },
  },
  activated() {
    document.querySelector(".compass").scrollTop =
      sessionStorage.getItem("scrollTop") || 0;
  },
  created() {
    // console.log(this.$route.query.channel);
    //获取高度
    //  获取本地存储的系统
    this.aType = localStorage.getItem("helpCenterType");
    if (this.aType === "android" || this.aType === "ios") {
      this.heights = window.innerHeight;
    }
    if (this.$route.query.memberId) {
      this.$store.commit("memberIdDocument", this.$route.query.memberId);
    }
  },

  methods: {
    toggleExpand(index) {
      this.lists[index].expanded = !this.lists[index].expanded;
    },
    toggleExpandExport(index) {
      this.lists[index].export = !this.lists[index].export;
    },
    async getById() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$store.state.memberIdDocument,
        this.$route.query.channel
      );
      this.channel = this.$route.query.channel;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
      //  console.log(this.channel);
    },
    async getList() {
      await this.getById();
      if (this.page_no > 2) {
        if (this.$store.state.memberIdDocument == "") {
          this.loginTitle = "登录后查看更多内容";
          this.finishedText = "";
          this.finished = true;
          return;
        }
      }
      let data = {
        page_no: this.page_no,
        page_size: 10,
        title: this.search_value,
        disease: this.diseaseName == "全部" ? null : this.diseaseName,
        maker: this.MakerName == "全部" ? null : this.MakerName,
        year: this.year,
      };
      const result = await getdocumentCompass(data);
      if (result.code === 0) {
        this.loading = false;
        let list = result.data;
        list.forEach((item) => {
          item.show_date = item.show_date
            ? moment(item.show_date).format("YYYY年MM月DD日")
            : null;
          item.expanded = false;
          item.export = false;
          // 未读
          // if (item.is_read == 0) {
          //   item.readIcon = false; //用于去掉标记
          // }
        });

        this.lists = this.lists.concat(list);
        // console.log(this.lists.length);
        if (this.lists.length >= result.count) {
          this.finished = true;
          this.finishedText = "没有更多了";
        }
        if (this.lists.length < result.count) {
          this.finished = false;
          this.page_no += 1;
          // console.log(this.page_no);
        }
        // if (list.length <= this.page_size) {
        //   this.finished = true;
        // }
        // if (this.lists.length >= result.count) {

        //   this.finished = true;

        // } else {
        //   this.finished = false;
        //   this.;
        // }
        // this.operationlist = [];

        //   this.lists.forEach((item, index) => {
        //     this.operationlist.push({ btnFold: false, textOver: false });
        //     this.$nextTick(() => {
        //       // 判断文本是否超过2行
        //       if (document.getElementById(index)) {
        //         let descHeight = window
        //           .getComputedStyle(document.getElementById(index))
        //           .height.replace("px", "");

        //         if (descHeight > 48) {
        //           this.operationlist[index].textOver = true;
        //         } else {
        //           this.operationlist[index].textOver = false;
        //         }
        //       }
        //     });
        //   });
        // }

        // this.exportlist = [];
        //   this.lists.forEach((item, index) => {
        //     this.exportlist.push({ btnFoldExport: false, textOverExport: false });
        //     // console.log("zhuanjia", this.operationlist);
        //     this.$nextTick(() => {
        //       // 判断文本是否超过2行
        //       if (document.getElementById(`ulid${index}`)) {
        //         let descHeight = window
        //           .getComputedStyle(document.getElementById(`ulid${index}`))
        //           .height.replace("px", "");
        //         if (descHeight > 48) {
        //           this.exportlist[index].textOverExport = true;
        //         } else {
        //           this.exportlist[index].textOverExport = false;
        //         }
        //       }
        //     });
        //   });
        // },
      }
    },

    async DocumentDiv(id, pdf_url, title, index) {
      // 已读埋点
      this.record(id, "auto");
      this.lists[index].is_read = 1;
      //记录点击操作 埋点
      const response = await recordOperationWX(
        id,
        "DOCUMENT",
        "DOCUMENT_LIST",
        this.aType,
        this.$store.state.memberIdDocument,
        this.version,
        this.$route.query.channel
      );
      if (response.code === 0) {
        if (this.$store.state.memberIdDocument) {
          this.$router.push({
            path: "/pdfVue",
            query: {
              url: "pdf/web/viewer.html?file=" + pdf_url,
              title: title,
              id: id,
              channel: this.channel,
              memberId: this.$store.state.memberIdDocument,
            },
          });
        } else {
          this.$router.push({
            path: "/pdfVue",
            query: {
              url: "pdf/web/viewer.html?file=" + pdf_url,
              title: title,
              id: id,
              channel: this.channel,
            },
          });
        }

        let listScrollTop = document.querySelector(".compass").scrollTop;
        sessionStorage.setItem("scrollTop", listScrollTop);
        console.log(listScrollTop);
      }
    },
    onSearch(value) {
      //  1.获取搜索框中的内容
      this.search_value = value;
      //清空列表，否则会出现重复,将页数置1
      this.lists = [];
      this.page_no = 1;
      this.getList();
    },
    cancel() {
      this.lists = [];
      this.page_no = 1;
      this.search_value = "";
      this.getList();
    },

    handlerScroll() {
      var scrollTop = this.$refs.personDom.scrollTop; //滑入屏幕上方的高度
      var windowHeitht = this.$refs.personDom.clientHeight; //能看到的页面的高度
      var scrollHeight = this.$refs.personDom.scrollHeight; //监控的整个div的高度（包括现在看到的和上下隐藏起来看不到的）
      let total = scrollTop + windowHeitht;
      if (total == scrollHeight) {
        this.page_no++;
        this.getList();
      }
    },
    back() {
      this.type = "";
      this.lists = [];
      this.page_no = 1;
      this.getList();
      (this.loading = true), (this.finished = false);
      this.status = true;
    },

    // 获取制定者
    async getDocumentMaker() {
      this.status = false;
      this.lists = [];
      this.page_no = 1;
      const res = await getMaker();
      if (res.code == 0) {
        this.filterMaker(res.data);
      }
      // var title = document.getElementById("title");
      // var num = document.getElementById("back").offsetHeight + 8;
      // title.style.paddingTop = `${num}px`;
    },
    // 获取疾病
    async getDocumentDisease(disease) {
      this.lists = [];
      this.page_no = 1;
      this.type = disease;
      this.status = false;
      const res = await getDisease();
      if (res.code == 0) {
        this.filterMaker(res.data);
      }
    },

    filterMaker(makerList) {
      let letterArray = []; // 定义一个数组来接受A、B、C、D这些type值
      for (let i = 65; i < 91; i++) {
        letterArray.push(String.fromCharCode(i)); // 使用String.fromCharCode将ASCII码值转化为对应的大写字母
      }
      let newMaker = []; // 定义一个数组来接收我们每个字母对应的数据
      letterArray.forEach((item) => {
        newMaker.push({
          type: `${item}`,
          list: makerList.filter(
            (item1) => item1.pinyin.substring(0, 1).toUpperCase() === `${item}`
          ),
        });
      });
      newMaker = newMaker.filter((item) => item.list.length);
      this.indexBar = newMaker;
      this.indexList = newMaker.map((item) => item.type);
    },
    selectMaker(data) {
      this.MakerName = data.name;
      this.back();
    },
    selectMaker1() {
      this.MakerName = "全部";
      this.back();
    },

    selectDisease(data) {
      this.diseaseName = data.name;
      this.back();
    },
    selectMaker2() {
      this.diseaseName = "全部";
      this.back();
    },

    async getYear() {
      const res = await getDocumentYear();
      this.yearlist = res.data;
      this.yearlist = ["全部", ...this.yearlist];
      console.log(this.yearlist);
      this.datashow = true;
    },
    onConfirm(value) {
      if (value == "全部") {
        this.year = "";
      } else {
        this.year = value;
      }
      this.datashow = false;
      // 确认后发送列表请求
      this.lists = [];
      this.page_no = 1;
      this.getList();
    },
    onCancel() {
      this.datashow = false;
    },
    // 已读
    async record(id, type, index) {
      let data = {
        memberId: this.$store.state.memberIdDocument,
        contentId: id,
        type: type, //click手动标记为已读，auto打开pdf阅读后标记为已读
      };
      const res = await postRecord(data);
      if (res.code == 0) {
        this.lists[index].is_read = 1;
      }

      // if (res.code == 0) {
      //   // 标记成功刷新列表
      //   this.lists = [];
      //   this.page_no = 1;
      //   this.getList();
      // }
    },
    goCollect() {
      if (this.$store.state.memberIdDocument == "") {
        this.$router.push({
          path: "/documentCompassCollect",
          query: {
            channel: this.channel,
          },
        });
      } else {
        this.$router.push({
          path: "/documentCompassCollect",
          query: {
            channel: this.channel,
            memberId: this.$store.state.memberIdDocument,
          },
        });
      }
    },

    openLogin() {
      this.loginShow = true;
      this.loginTitle = "登录后查看更多内容";
    },
  },
};
</script>

<style lang="scss" scoped>
.compass {
  height: 100vh;
  background-color: #f5f5f8;
  overflow: scroll;
  position: relative;
  //   box-sizing: border-box;
  .van-search__content {
    border-radius: 10px;
  }
  .search {
    width: 100%;
    margin-bottom: 54px !important;
    // background: #199bfb !important;
    position: fixed;
    z-index: 999;
  }
  .document-compass {
    width: 95%;
    background-color: #ffffff;
    margin: 0 auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .document-word {
    width: 100%;
    .document-word-title {
      padding: 10px 1px 0px 1px;
      width: 95%;
      margin: auto;
      //   display: -webkit-box;
      //   text-overflow: ellipsis; /*超出内容用省略号*/
      //   overflow: hidden; /*内容超出后隐藏*/
      //   -webkit-box-orient: vertical;
      //   -webkit-line-clamp: 3; /*2行*/
      font-family: PingFang-SC-Bold;
      color: #333333;
      font-size: 16px;
      font-weight: 550;
    }
    .document-word-content {
      letter-spacing: 1px;
      position: relative;
      width: 95%;
      margin: auto;
      padding-top: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #565555;
      display: -webkit-box;
      text-overflow: ellipsis; /*超出内容用省略号*/
      overflow: hidden; /*内容超出后隐藏*/
      -webkit-box-orient: vertical;
    }
    .expand-btn {
      font-size: 12px;
      cursor: pointer;
      color: #66a6d4;
      text-align: right;
      padding-right: 16px;
    }
    .document-word-expert {
      width: 95%;
      margin: auto;
      padding-top: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #565555;
      // display: -webkit-box;
      // text-overflow: ellipsis; /*超出内容用省略号*/
      // overflow: hidden; /*内容超出后隐藏*/
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 2; /*1行*/
    }
    .document-word-date {
      width: 95%;
      margin: auto;
      font-size: 12px;
      padding-top: 10px;
      font-weight: 400;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      color: #565555;
    }
    .document-word-new {
      display: inline-block;
      background: #e0d751;
      padding: 1px 3px;
      font-size: 12px;
      color: white;
      border-radius: 5px;
      font-weight: 400;
    }
  }
}
.select {
  width: 95%;
  margin: 0px auto;
  margin-top: 65px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  .select_item {
    width: 29%;
    display: flex;
    line-height: 24px;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding-left: 4px;
    border-radius: 5px;
    .name {
      width: 79%;
      font-size: 12px;
      color: #999999;
      text-align: center;
    }
    .icon {
      width: 20%;
      font-size: 12px;
      color: #999999;
    }
    .sss {
      width: 79%;
      font-size: 12px;
      color: #199bfb;
      text-align: center;
      display: -webkit-box;
      text-overflow: ellipsis; /*超出内容用省略号*/
      overflow: hidden; /*内容超出后隐藏*/
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1; /*1行*/
    }
  }
}
.indexTop {
  width: 100%;
  margin-bottom: 14px;
  background: white;
  position: fixed;
  z-index: 999;
}
.back {
  width: 100%;
  height: 40px;
  background: white;
  position: fixed;
  z-index: 999;
  border-bottom: 1.5px solid #f5f5f8;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 18px;
  color: #323233;
}
.blueback {
  width: 100%;
  height: 40px;
  background: white;
  position: fixed;
  z-index: 999;
  color: #199bfb;
  border-bottom: 1.5px solid #f5f5f8;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 20px;
  display: -webkit-box;
  text-overflow: ellipsis; /*超出内容用省略号*/
  overflow: hidden; /*内容超出后隐藏*/
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /*1行*/
}
.over {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 92%;
}
.over1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 92%;
}
.inner {
}

.btn {
  font-size: 10px;
  // float: right;
  cursor: pointer;
  color: #199bfb;
  position: absolute;
  right: 0;
  bottom: 0;
}
.van-picker__toolbar {
  position: fixed;
  z-index: 99;
  width: 100%;
  background: white;
}
.collect {
  z-index: 999;
  position: fixed;
  bottom: 70px;
  right: 5px;
  width: 40px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
  }
}
.list {
  margin-top: 100px;
}
.topBox {
  width: 100%;
  height: 100px;
  background: #f5f5f8 !important;
  position: fixed;
  z-index: 999999;
}
</style>
