import axios from "axios";

const header = "https://yun-new-admin-api.yunicu.com"; //正式环境
const headerTool = "https://application.yunicu.com"; //正式环境

// const headerTool = "http://192.168.5.51:33333";
const headerRecruitment = "https://application.yunicu.com"

// const header = "http://admin-dev-api.yunicu.com"; //测试环境
// const header= 'http://192.168.31.160:10086' // 韩笑本地
// const header = "https://yun-new-admin-api.yunicu.com"; //正式环境
// const header = "http://admin-dev-api.yunicu.com"; //测试环境
// const header= 'http://192.168.31.160:10086' // 韩笑本地
//现场环境
// const header='http://192.168.31.102:10086'

// const header='http://192.168.31.129:10086'
// const header='http://192.168.31.129:55555'
// const header='http://43.142.107.84'
// const header='http://192.168.31.160:10086'
// const header = 'http://34917c36t0.zicp.fun'
// const header = "http://192.168.5.57:33333";

import qs from "qs";

let admins;
export function setById(data) {
  admins = qs.parse(data);
 
}



export function getByIds(url, params) {
  return axios
    .get(header + url, {
      headers: {
        byId: params.byId,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function get(url, params) {
  return axios
    .get(header + url, {
      headers: {
        "x-token": admins.byId,
        type: 1,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function getRt(url, params) {
  return axios
    .get(headerRecruitment + url, {
      headers: {
        "x-token": admins.byId,
          byId: admins.byId,
          channel: admins.channel,
          os: admins.os,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function getRtById(url, params, headerData) {
  return axios
    .get(headerRecruitment + url, {
      headers: {
          //用户id
          byId: admins.byId,
          //用户端代码
          channel: admins.channel,
          os: admins.os,
          ...headerData
          // "ip":admins.ip,
          // version: admins.version,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

//文献指南-招聘-杂志获取用户行为记录
export function getById(url, params) {
  return axios
    .get(header + url, {
      headers: {
        //用户id
        byId: admins.byId,
        //用户端代码
        channel: admins.channel,
        os: admins.os,
        // "ip":admins.ip,
        version: admins.version,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
//招聘用户行为记录
export function getTool(url, params) {
  return axios
    .get(headerTool + url, {
      headers: {
        //用户id
        byId: admins.byId,
        //用户端代码
        channel: admins.channel,
        os: admins.os,
        // "ip":admins.ip,
        version: admins.version,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postHeader(url, params) {
  return axios
    .post(header + url, params)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function post(url, params) {
  return axios
    .post(header + url, params)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postRt(url, params) {
  return axios
    .post(headerRecruitment + url, params, {
        headers: {
            //用户id
            "x-token": admins.byId,
            //用户端代码
            channel: admins.channel,
            os: admins.os,
            // "ip":admins.ip,
            version: admins.version,
        },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postHdById(url, params, headerData) {
  return axios
    .post(header + url, params, {
        headers: {
            byId: admins.byId,
            //用户端代码
            channel: admins.channel,
            os: admins.os,
            ...headerData
        },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function postToken2(url, params) {
    return axios
        .post(header + url, params, {
            headers: {
                //用户id
                byId: admins.byId,
                //用户端代码
                channel: admins.channel,
                os: admins.os,
                // "ip":admins.ip,
                version: admins.version,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
        });
}

export function postToken(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
        byId: params.byId,
        channel: params.channel,
        os: params.os,
        // "ip":params.ip,
        version: params.version,
        contentId: params.contentId,
        location: params.location,
        type: params.type,
        "x-token": params.byId, 
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function put(url, params) {
  return axios
    .put(header + url, params)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function postById(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
        //用户id
        byId: admins.byId,
        //用户端代码
        channel: admins.channel,
        os: admins.os,
        // "ip":admins.ip,
        version: admins.version,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function del(url, params) {
  return axios
    .delete(
      header + url,
      {
        headers: {
          //用户id
          byId: admins.byId,
          //用户端代码
          channel: admins.channel,
          os: admins.os,
          // "ip":admins.ip,
          version: admins.version,
          "x-token": admins.byId,
        },
      },
      params
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function delRt(url, params) {
  return axios
    .delete(
        headerRecruitment + url,
      {
        headers: {
          //用户id
          byId: admins.byId,
          //用户端代码
          channel: admins.channel,
          os: admins.os,
          // "ip":admins.ip,
          version: admins.version,
          "x-token": admins.byId,
        },
      },
      params
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function getXToken(url, params) {
  return axios
    .get(header + url, {
      headers: {

      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}


// 医学公式
export function getByIdF(url, params) {
  return axios
    .get(headerTool + url, {
      headers: {
        //用户id
        byId: admins.byId,
        //用户端代码
        channel: admins.channel,
        os: admins.os,
        // "ip":admins.ip,
        version: admins.version,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postByIdF(url, params) {
  return axios
    .post(headerTool + url, params, {
      headers: {
        //用户id
        byId: admins.byId,
        //用户端代码
        channel: admins.channel,
        os: admins.os,
        // "ip":admins.ip,
        version: admins.version,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function putF(url, params) {
  return axios
    .put(headerTool + url, params)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function delF(url, params) {
  return axios
    .delete(
      headerTool + url,
      {
        headers: {
          //用户id
          byId: admins.byId,
          //用户端代码
          channel: admins.channel,
          os: admins.os,
          // "ip":admins.ip,
          version: admins.version,
          "x-token": admins.byId,
        },
      },
      params
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function getXTokenF(url, params) {
  return axios
    .get(headerTool + url, {
      headers: {},
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postToken2F(url, params) {
  return axios
    .post(headerTool + url, params, {
      headers: {
        //用户id
        byId: admins.byId,
        //用户端代码
        channel: admins.channel,
        os: admins.os,
        // "ip":admins.ip,
        version: admins.version,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function getByIdX(url, params) {
  return axios
    .get(headerTool + url, {
      headers: {
        //用户id
         "x-token": admins.byId,
        //用户端代码
        channel: admins.channel,
        os: admins.os,
        // "ip":admins.ip,
        version: admins.version,
      },
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}